export default {
    "at": {
        fallback: undefined,
        content: {
            error: {
                networkNotAvailable: {
                    title: "Netzwerkfehler",
                    message: "Die Gegenstelle ist aktuell nicht erreichbar!"
                },
                unknownError: "Unbekannter Fehler",
                tryAgain: "Bitte versuchen Sie es nochmal!",
                loginFailed: "Anmeldevorgang fehlgeschlagen",
                invalidCredentials: "Kombination aus E-Mail und Passwort ungültig!",
                parameterCredentialsTimout: "Die aktuelle Sitzung ist abgelaufen!",
                invalidRegistrationType: "Der ausgewählte Registrierungstyp ist nicht vorhanden! Möglichkeiten: Person, Apotheke",
                personProfileLimitReached: "Sie haben bereits die maximale Anzahl an Profilen erreicht!",
                verify: "Hinweis",
                accountAlreadyActivated: "Das Konto wurde bereits aktiviert",
                createProfile: "Fehler beim Erstellen!",
                passwordChangeFailed: "Das Passwort konnte nicht geändert werden. Die Eingabe im Feld aktuelle Passwort ist falsch.",
                registerFailed: "Registrierung fehlgeschlagen!",
                emailAlreadyInUse: "E-Mail Adresse ist bereits registriert!",
                validationFailed: "Validierung fehlgeschlagen!",
                emailAlreadyInUse: "Diese Email-Adresse ist bereits in Verwendung."
            },
            success: {
                newPasswordSent: "Sie haben von uns eine E-Mail mit dem neuen Passwort erhalten!",
                activationMailResent: "Sie haben von uns eine E-Mail mit den nächsten Schritten erhalten!",
                verify: "Das Benutzerkonto wurde aktiviert! Sie können sich jetzt einloggen!",
                notificationsUpdate: "Ihre Änderungen wurden erfolgreich gespeichert!",
                update: "Neue Werte übernommen!",
                passwordChanged: "Neues Passwort übernommen!",
                createProfile: "Das Profil wurde erstellt!",
                updateProfile: "Die Änderungen wurden gespeichert!",
                acceptMail: "Ihr Benutzerkonto wurde erstellt! Sie müssen nur noch die E-Mail in Ihrem Postfach akzeptieren!",
                accountDeleted: "Ihr Benutzerkonto wurde gelöscht!",
                someAccountDeleted: "Das Benutzerkonto wurde gelöscht!", /* CS: text for some account deleted */
                someAccountActivated: "Das Benutzerkonto wurde aktiviert!", /* CS: text for some account being manually activated */
                impersonationActive: "Impersonation aktiviert für", /* CS 21/12/21: message when admin successfully initiated an impersonation */
                impersonationTerminated: "Impersonation beendet!", /* CS 21/12/21: message when admin successfully terminates an impersonation session */
            },
            notification: {
                error: "Fehler",
                success: "Erfolg",
                info: "Information"
            },
            available: {
                now: "Sofort verfügbar",
                in: "Verfügbar in",
                replacement: "Springerdienst",
                nightly: "Nachtdienst"
            },
            contact: {
                inquiry: "Kontaktieren",
                inquiryTooltip: "",
                revoke: "Kontaktanfrage beenden",
                revokeTooltip: "Kontaktanfrage wurde gesendet / Kontaktanfrage beenden",
                delete: "Kontakt beenden",
                deleteTooltip: "Kontaktanfrage wurde gesendet / Kontaktanfrage beenden",
                blacklist: "Blockieren",
                blacklistedTooltip: "Dieser Eintrag ist blockiert",
                blacklistTooltip: "Diesen Eintrag blockieren (blacklist)",
                whitelist: "Blockierung aufheben",
                whitelistTooltip: "",
                loading: "Treffer werden geladen",
                accept: "Kontaktanfrage akzeptieren",
                acceptTooltip: "",
            },
            upload: {
                started: "Dokument wird hochgeladen!",
                success: "Dokument gespeichert!"
            },
            mismatch: {
                password: "Passwort und Bestätigung stimmen nicht überein."
            },
            placeholder: {
                name: "Name",
                firstname: "Vorname",
                lastname: "Nachname",
                gender: "Geschlecht",
                postalArea: "PLZ / Ort",
                email: "E-Mail",
                phoneNumber: "Telefonnummer",
                phone: "Tel",
                password: "Passwort",
                enterPassword: "Passwort (min. 8 Zeichen)",
                newPassword: "neues Passwort",
                passwordOld: "aktuelles Passwort",
                passwordConfirm: "Passwort bestätigen",
                displayPassword: "Passwort anzeigen",
                displayAllContactDetails: "Alle Kontaktdetails anzeigen",
                jobTypes: {
                    person: "Ich suche eine Stelle als:",
                    pharmacy: "Ich biete eine Stelle als:"
                },
                nationality: "Staatsbürgerschaft",
                address: "Adresse",
                companyName: "Firmenname",
                
                titleWeekdays: "Kalenderwoche",
                titleAlternateWeekdays: "Ungerade Kalenderwoche",
                titleFlexibleCalendarWeek: "Flexibel",
                titleProfileAvailability: "Springerdienst-Zeiträume",
                profileAvailabilityHeader: "An folgenden Tagen kann ich meine Springerdienste anbieten",
                profileAvailabilityAddNew: "Neuer Zeitraum",
                profileAvailabilityDescription: " ",
                profileAvailabilityDateUntil: "BIS",
                titleOtherAvailability: "Andere Einstellungen",

                // weekdays
                monday: "Montag",
                tuesday: "Dienstag",
                wednesday: "Mittwoch",
                thursday: "Donnerstag",
                friday: "Freitag",
                saturday: "Samstag",
                sunday: "Sonntag",
                // alternating weekdays
                altMonday: "Montag",
                altTuesday: "Dienstag",
                altWednesday: "Mittwoch",
                altThursday: "Donnerstag",
                altFriday: "Freitag",
                altSaturday: "Samstag",
                altSunday: "Sonntag",

                nightlyShifts: "Nachtdienst",
                overpay: "Überbezahlung",
                accomodation: "Dienstwohnung",
                management: "Leitung",
                licenceHolder: "Konzessionär",
                covidTester: "COVID-19 Tester",
                lindaMember: "Linda Apotheken Mitglied",
                lindaExperience: "Ich habe bereits in einer Linda Apotheke gearbeitet",
                replacement: "Bereitschaft zum Springerdienst (Urlaubs-/Krankenvertretung)",
                documentTypes: "Dokumenttyp auswählen",
                health: {
                    replacement: "Urlaubs-/Krankenvertretung",
                    titleProfileAvailability: "Urlaubs-/Krankenvertretung-Zeiträume",
                    profileAvailabilityHeader: "An folgenden Tagen kann ich meine Vertretung anbieten",
                    titleWeekdays: "Kalenderwoche",
                    titleAlternateWeekdays: "Ungerade Kalenderwoche",
                    companyName: "Firmenname/Privat",
                    phone: "Telefon",
                }
            },
            matching: {
                matching: "Treffer",
                replacement: "Springerdienste",
                replacement_subtitle: "Urlaubs-/Krankenvertretung",
                blocked: "Blockiert",
                emptyMatches: "keine Treffer",
                licenceHolders: "Konzessionäre",
                covidTesters: "COVID-19 Tester"
            },
            text: {
                confirm: {
                    text: "Wirklich fortfahren?",
                    deleteProfile: "Möchten Sie dieses Profil wirklich löschen? Dieser Schritt kann nicht rückgängig gemacht werden!",
                    deleteAccount: "Möchten Sie Ihr Benutzerkonto wirklich löschen? Dieser Schritt kann nicht rückgängig gemacht werden!",
                    deleteContact: "Möchten Sie wirklich den Kontakt abbrechen?",
                    blacklistContact: "Möchten Sie diesen Treffer wirklich blockieren?"
                },
                documents: "Dokumente",
                profile_: "Profil",
                activate: "Aktivieren",
                availableIn: "Verfügbar in",
                profileMatches: "Übereinstimmung: ",
                openMatch: "Profil anzeigen",
                week: "Woche(n)",
                month: "Monat(e)",
                hour: "Stunde(n)",
                quarter: "Viertel",
                tenth: "Zehntel",
                showTerms: "AGB öffnen",
                hasWorkPermission: "Ich bin Inhaber eines Aufenthaltstitels, der zur Ausübung einer unselbständigen Tätigkeit ohne Beschäftigungsbewilligung nach dem österreichischen Ausländerbeschäftigungsgesetz (AuslBG) oder zur Ausübung einer nichtselbständigen Arbeit ohne Zustimmung der Bundesagentur für Arbeit nach der deutschen Beschäftigungsverordnung (BeschV) berechtigt.",
                acceptNewsletter: "Ich möchte über alle wichtigen Themen, neuen Produkte, Funktionen und Angebote von Apopersonal informiert werden.",
                acceptTermsInternal: "Mit der Übertragung der E-Mail-Adresse akzeptiere ich die allgemeinen Geschäftsbedingungen der Apopersonal GmbH.",
                registerInformation: "Nach erfolgreicher Registrierung haben Sie die Möglichkeit weitere Informationen (Foto, Lebenslauf, Zeugnisse, Motivationsschreiben, etc.) hochzuladen.",
                welcomeTo: "Willkommen bei",
                resetPassword: "Passwort vergessen?",
                edit: "Bearbeiten",
                deactivate: "Deaktivieren",
                delete: "Löschen",
                open: {
                    default: "Öffnen",
                    pharmacy: "Traumpersonal anzeigen",
                    person: "Traumapotheken anzeigen",
                    health:{
                        default: "Öffnen",
                        pharmacy: "Traumpersonal anzeigen",
                        person: "Traumjobs anzeigen",
                    }
                },
                otherCashierSystems: "Weitere IT Systeme",
                creationDate: "Erstellt am",
                upload: "Datei hochladen",
                hours: "Stunden",
                hoursPerWeek: "Wochenstunden",
                lastSeen: "Zuletzt gesehen am:",
                dragOrSelectFileToUpload: "Datei auswählen oder reinziehen",
                dragOrSelectImageToUpload: "Bild auswählen oder reinziehen",
                newsletter: "Newsletter",
                newPotentialMatch: "Neue potentielle Kontakte",
                contactRequested: "Kontaktanfrage",
                resendActivation: "Aktivierungsmail senden",
                login: {
                    subtitle: "Bitte melden Sie sich mit Ihren persönlichen Zugangsdaten an."
                },
                activationText: {
                    header: "Bestätigen Sie Ihre E-Mail Adresse!",
                    subtitle: "Wir haben Ihnen ein E-Mail zukommen lassen. "
                },
                acceptTerms: {
                    header: "Wir haben unsere AGBs aktualisiert!",
                    subtitle: "Bitte lesen Sie sich die neuen AGB durch und akzeptieren diese, um das Service weiterhin nutzen zu können!"
                },
                updateNationality: {
                    header: "Bitte geben Sie Ihre Nationalität an!",
                    subtitle: "Aus rechtlichen Gründen müssen Sie Ihre Nationalität angeben, um das Service weiterhin nutzen zu können!"
                },
                resetPassword1: {
                    title: "Passwort vergessen?",
                    subtitle: "Kein Problem - Geben Sie Ihre E-Mail Adresse ein und wir schicken Ihnen ein neues Passwort zu!"
                },
                account: {
                    delete: "Konto löschen",
                    data: "Persönliche Daten",
                    documents: "Dokumente",
                    newsletter: "Benachrichtigungen",
                    password: "Passwort ändern",
                    profile: "Meine Profile",
                    jobseeker_profiles: "Meine Jobsuche",
                    pharmacy_profiles: "Meine Stellenausschreibungen"
                },
                profile: {
                    jobType: "Stelle",
                    availability: "Verfügbarkeit",
                    knowledge: "Kenntnisse",
                    skills: "Dienstbereiche",
                },
                temp: {
                    language: "Sprachen",
                    cashierSystem: "IT Systeme",
                    customer: "Kundenbetreuung/Fachwissen",
                    admin: "Administration",
                    manufactur: "Herstellung/Rezeptur",
                    logistic: "Warenlogistik",
                    marketing: "Marketing",
                    health: {
                        language: "Sprachen",
                        customer: "Spezialisierung",
                        admin: "Pflegedokumentationssysteme"
                    }
                },
                matching: {
                    matching: "Treffer",
                    replacement: "Springer"
                },
                register: {
                    step1: {
                        person: "Erstellen Sie jetzt Ihr persönliches Suchprofil! Sie sind nur ein paar Schritte von Ihrem Traumjob entfernt.",
                        pharmacy: "Erstellen Sie jetzt das Anforderungsprofil für Ihre freie Stelle! Sie sind nur ein paar Schritte von Ihrem neuen Mitarbeiter entfernt."
                    },
                    step2: {
                        person: "Bitte sagen Sie uns ab wann, an welchen Tagen und wie viel Stunden Sie arbeiten möchten.",
                        pharmacy: "Bitte sagen Sie uns ab wann, an welchen Tagen und wie viele Stunden Sie neu besetzen möchten?"
                    },
                    step3: {
                        person: "Welche Sprachkenntnisse und EDV-Systeme beherrschen Sie?",
                        pharmacy: "Welche Sprachen-Kenntnisse und EDV-System sind vom dem/der Kandidaten/In gewünscht?"
                    },
                    step4: {
                        person: "Im welchen Dienstbereichen wollen Sie gerne arbeiten?",
                        pharmacy: "Im welchen Dienstbereichen wollen Sie den neuen Mitarbeiter einsetzen?"
                    },
                    step5: {
                        person: "Zu guter Letzt bitten wir Sie noch um Ihre persönliche Daten.",
                        pharmacy: "Zu guter Letzt bitten wir Sie noch um Ihre Unternehmensdaten.",
                        activationNote: "Nach Abschluss der Registrierung erhalten Sie eine E-Mail mit einem Aktivierungslink. Erst nach erfolgter Aktivierung können Sie sich auf Apopersonal anmelden."
                    },
                    health: {
                        step1: {
                            person: "Erstellen Sie jetzt Ihr persönliches Suchprofil! Sie sind nur ein paar Schritte von Ihrem Traumjob entfernt.",
                            pharmacy: "Erstellen Sie jetzt das Anforderungsprofil für Ihre freie Stelle! Sie sind nur ein paar Schritte von Ihrem neuen Mitarbeiter entfernt."
                        },
                        step2: {
                            person: "Bitte sagen Sie uns ab wann, an welchen Tagen und wie viel Stunden Sie arbeiten möchten.",
                            pharmacy: "Bitte sagen Sie uns ab wann, an welchen Tagen und wie viele Stunden Sie neu besetzen möchten?"
                        },
                        step3: {
                            person: "Welche Sprachen und Pflegedokumentationssysteme beherschen Sie?",
                            pharmacy: "Welche Sprachen-Kenntnisse und Spezialisierung sind vom dem/der Kandidaten/In gewünscht?",
                            // note: "(Deutsch ist vorausgesetzt)"
                        },
                        step4: {
                            person: "Im welchen Dienstbereichen wollen Sie gerne arbeiten?",
                            pharmacy: "Im welchen Dienstbereichen wollen Sie den neuen Mitarbeiter einsetzen?"
                        },
                        step5: {
                            person: "Zu guter Letzt bitten wir Sie noch um Ihre persönliche Daten.",
                            pharmacy: "Zu guter Letzt bitten wir Sie noch um Ihre Unternehmensdaten.",
                            activationNote: "Nach Abschluss der Registrierung erhalten Sie eine E-Mail mit einem Aktivierungslink. Erst nach erfolgter Aktivierung können Sie sich auf Pflege-Finden anmelden."
                        }
                    }
                },
                signupAsPerson: "Traumapotheke finden",
                signupAsPharmacy: "Traumpersonal finden",
                health: {
                    signupAsPerson: "Traumjob finden",
                    signupAsCompany: "Traumpersonal finden"
                },
                activationTextHeader: "Noch ein letzter Schritt ...",
                registerTextHeader: {
                    person: "Erstellen Sie jetzt Ihr persönliches Suchprofil!",
                    pharmacy: "Erstellen Sie jetzt das Anforderungsprofil für Ihre freie Stelle!"
                },
                registerText: {
                    person: "Sie sind nur fünf Schritte von Ihrem Traumjob entfernt.",
                    pharmacy: "Sie sind nur fünf Schritte von Ihrem neuen Mitarbeiter entfernt."
                },
                registerTextInfo: "Nach erfolgreicher Registrierung erhalten Sie von uns ein E-Mail mit Ihren persönlichen Zugangsdaten.",
                activationMailNotReceivedText: "Sie haben keine E-Mail von uns erhalten? Kein Problem, wir schicken Ihnen ein neues E-Mail!"
            },
            gender: {
                male: "Männlich",
                female: "Weiblich",
                other: "Divers",
                0: "Divers",
                1: "Weiblich",
                2: "Männlich"
            },
            languages: {
                text: "Sprachen",
                desc: "(Deutsch ist vorausgesetzt)",
                ar: "Arabisch",
                en: "Englisch",
                es: "Spanisch",
                fa: "Persisch",
                fr: "Französisch",
                hi: "Hindi",
                hr: "Kroatisch",
                hu: "Ungarisch",
                it: "Italienisch",
                jp: "Japanisch",
                ru: "Russisch",
                sl: "Slowenisch",
                sr: "Serbisch",
                zh: "Chinesisch",
                tr: "Türkisch",
                // CS 20/4/22: newly supported languages
                br: "Bulgarisch",
                ro: "Rumänisch",
                sk: "Slowakisch",
                ua: "Ukrainisch"
            },
            shifts: {
                "0": "Vormittags",
                "1": "Nachmittags",
                "2": "Ganztags",
                "3": "Nein",
                "4": "Nacht"
            },
            jobTypes: {
                aspirant: "Aspirant/in",
                drogist: "Drogist/in",
                other: "Sonstiger Job",
                pharmacist: "Apotheker/in",
                pka: "PKA",
                pta: "PTA",
                student: "Student/in",
                trainee: "Lehrling",
                // germany specific job types
                pharmaingenieur: "Pharmaingenieur/-in",
                ptaintern: "PTA Praktikant/-in",

                //        ___           ___       __        __   ___ 
                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                //         
                health: {
                    "pdlpm": "Leiter/in des Pflegedienstes an Einrichtungen pflegeb. Menschen",
                    "pfa": "Pflegefachassisten/in",
                    "pfa2": "Pflegefachassisten/in",
                    "pfa3": "Pflegefachassisten/in",
                    "pdlka": "Leiter/in des Pflegedienstes an einer Krankenanstalt",
                    "bsc": "Dipl. Gesundheits- und Krankenpfleger/-in",
                    "bsc2": "Dipl. Gesundheits- und Krankenpfleger/-in",
                    "pa": "Pflegeassistent/in",
                    "pa2": "Pflegeassistent/in",
                    "pa3": "Pflegeassistent/in",
                    "other": "Sonstiger Job",
                },
            },
            cashierSystems: {
                text: "IT Systeme",
                commissioningSystem: "Kommissionierautomat",
                ait: "AIT",
                ait2: "AIT/2",
                aposys: "Aposys",
                apotronik: "Apotronik",
                avs: "AVS",
                datapharm: "DataPharm",
                optipharm: "Optipharm",
                taraManager: "Tara Manager",
                lauerFischer: "Lauer Fischer",
                adg: {
                    a3000: "ADG A3000",
                    s3000: "ADG S3000"
                },
                awinta: {
                    pharmasoft: "awinta PHARMASOFT",
                    asys: "awinta Asys",
                    prokas: "awinta PROKAS",
                    infopharm: "awinta INFOPHARM",
                    jump: "awinta jump",
                    awintaOne: "awintaOne"
                },
                pharmatechnik: {
                    xtPt: "PHARMATECHNIK XT",
                    ixos: "PHARMATECHNIK IXOS"
                },
                cida: {
                    novaPlus: "CIDANova Plus",
                    classic: "CIDAClassic",
                    nova: "CIDANova"
                },
                prismaDatensysteme: "PRISMA Datensysteme",
                adv: "ADV",
                // SWITZERLAND
                pharamsoftsarl: "Pharmasoft Sàrl",
                cseag: "CSE AG",
                datagest: "Datagest Sàrl",
                daufsa: "Dauf SA",
                propharma: "ProPharma Systems AG",
                pharmatic: {
                    goldengate: "Pharmatic - Golden Gate",
                    tactil: "Pharmatic - Tactil"
                },
                hci: {
                    pharma4: "HCI Solutions AG - pharma4",
                    triapharm: "HCI Solutions AG - TriaPHARM"
                },
                inphasa: "Inpha SA"
            },
            commissioningSystems: {
                apostore: "APO STORE",
                gollmann: "GOLLMANN",
                rowa: "ROWA",
            },
            document: {
                certificate: "Zertifikat",
                cv: "Lebenslauf",
                other: "Sonstige",
                picture: "Profilbild"
            },
            my: {
                profile: "Mein Suchprofil"
            },
            others: {
                profile: "Suchprofil der Kontaktperson"
            },
            customerSkills: {
                text: "Kundenbetreuung/Fachwissen"
            },
            adminSkills: {
                text: "Administration"
            },
            manufacturSkills: {
                text: "Herstellung/Rezeptur"
            },
            marketingSkills: {
                text: "Marketing",
                // marketingSkills in health edition will contain the initial skill description entered by user on STEP 3 register page
                health: { 
                    text: "Spezialisierung"
                }
            },
            logisticSkills: {
                text: "Warenlogistik"
            },
            notifications: {
                uploadImage: {
                    header: "Profilbild hochladen!",
                    text: "Apopersonal bietet die Möglichkeit ein Profilbild hochzuladen! Das Profilbild wird nur nach erfolgreicher Kontaktaufnahme angezeigt!",
                }
            },
            skills: {
                customer: {
                    text: "Kundenbetreuung/Fachwissen",
                    animalConsultation: "Tierberatung",
                    aromatherapy: "Aromatherapie/Ätherische Öle",
                    bachFlowerRemedies: "Bachblüten",
                    cosmeticConsultation: "Kosmetische Beratung",
                    homeopathicConsultation: "Homöopathische Beratung",
                    nutritionConsultation: "Ernährungsberatung",
                    pharmaceuticalConsultation: "Pharmazeutische Beratung",
                    prescriptionAcceptance: "Rezeptannahme",
                    schuesslerSalts: "Schüssler Salze",
                    tcmConsultation: "TCM Beratung"
                },
                manufactur: {
                    text: "Herstellung/Rezeptur",
                    bachFlowerRemedies: "Bachblüten",
                    homeopathicProduction: "Homoöpathische Herstellung Globuli/Dilution",
                    magistralFormula: "Magistrale Rezeptur",
                    tcmProduction: "TCM Herstellung"
                },
                logistic: {
                    text: "Warenlogistik",
                    inventory: "Inventur",
                    orderOfGoods: "Warenbestellung",
                    receivingInspection: "Eingangskontrolle",
                    storeGoodsOrganization: "Einräumen in Laden",
                    packaging: "Verblisterung Altenheim"
                },
                marketing: {
                    text: "Marketing",
                    decoration: "Dekoration",
                    socialMedia: "Social Media"
                },
                admin: {
                    text: "Administration",
                    prescriptionBilling: "Rezeptverrechnung",
                    sap: "SAP",
                    backOffice: "Backoffice",
                    krages: "Krages (Methox)",  // health 
                    health: {
                        text: "Pflegedokumentationssysteme",
                    }
                },
                //        ___           ___       __        __   ___ 
                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                //        
                health: {
                    children: "Kinder- und Jugendlichenpflege",
                    psychiatry: "Psychiatrische Gesundheits- und Krankenpflege",
                    intensive: "Intensivpflege",
                    anesthesia: "Anästhesiepflege",
                    surgery: "Pflege im OP-Bereich",
                    hygiene: "Krankenhaushygiene",
                    kidney: "Pflege bei der Nierenersatztherapie",
                    wound: "Wundmanagement und Stomaversorgung",
                    palliative: "Hospiz- und Palliativversorung",
                    endOfLife: "Sterbebegleitung",
                    dementia: "Pflege bei Demenz",
                    oncology: "Pflege in der Onkologie",
                    tcm: "TCM in der Pflege",
                    aromatherapy: "Aromatherapie"
                }
            },
            distance: {
                radius: "Angebote im Umkreis von:",
                "0": "Wohnort",
                "1000": "1000 km",
            },
            country: {
                at: "Österreich",
                de: "Deutschland",
                ch: "Schweiz",
            },
            nav: {
                impressum: "Impressum",
                contact: "Kontakt",
                terms: "AGB",
                logout: "Abmelden",
                login: "Anmelden",
                register: "Registrieren",
                registerAsNew: "Neu Registrieren",
                profile: "Profil"
            },
            invalid: {
                selection: "Ungültige Auswahl!",
                email: "Ungültige E-Mail Adresse!",
                password: "Ungültiges Passwort!",
                name: "Ungültiger Name!",
                companyName: "Ungültiger Firmenname!",
                address: "Ungültige Adresse!"
            },
            no: {
                email: "E-Mail Adresse erforderlich!",
                password: "Passwort erforderlich!",
                name: "Name erforderlich",
                companyName: "Firmenname erforderlich",
                address: "Adresse erforderlich"
            },
            nationalities: {
                ac: "Ascension",
                dg: "Diego Garcia",
                ea: "Ceuta, Melilla",
                ic: "Kanarische Inseln",
                ta: "Tristan da Cunha",
                xk: "Kosovo",
                af: "Afghanistan",
                eg: "Ägypten",
                ax: "Åland",
                al: "Albanien",
                dz: "Algerien",
                as: "Amerikanisch-Samoa",
                vi: "Amerikanische Jungferninseln",
                ad: "Andorra",
                ao: "Angola",
                ai: "Anguilla",
                aq: "Antarktika",
                ag: "Antigua und Barbuda",
                gq: "Äquatorialguinea",
                ar: "Argentinien",
                am: "Armenien",
                aw: "Aruba",
                az: "Aserbaidschan",
                et: "Äthiopien",
                au: "Australien",
                bs: "Bahamas",
                bh: "Bahrain",
                bd: "Bangladesch",
                bb: "Barbados",
                by: "Weißrussland",
                be: "Belgien",
                bz: "Belize",
                bj: "Benin",
                bm: "Bermuda",
                bt: "Bhutan",
                bo: "Bolivien",
                bq: "Bonaire, Sint Eustatius und Saba (Niederlande)",
                ba: "Bosnien und Herzegowina",
                bw: "Botswana",
                bv: "Bouvetinsel",
                br: "Brasilien",
                vg: "Britische Jungferninseln",
                io: "Britisches Territorium im Indischen Ozean",
                bn: "Brunei",
                bg: "Bulgarien",
                bf: "Burkina Faso",
                bi: "Burundi",
                cl: "Chile",
                cn: "Volksrepublik China",
                ck: "Cookinseln",
                cr: "Costa Rica",
                ci: "Elfenbeinküste",
                cw: "Curaçao",
                dk: "Dänemark",
                de: "Deutschland",
                dm: "Dominica",
                do: "Dominikanische Republik",
                dj: "Dschibuti",
                ec: "Ecuador",
                sv: "El Salvador",
                er: "Eritrea",
                ee: "Estland",
                fk: "Falklandinseln",
                fo: "Färöer",
                fj: "Fidschi",
                fi: "Finnland",
                fr: "Frankreich",
                gf: "Französisch-Guayana",
                pf: "Französisch-Polynesien",
                tf: "Französische Süd- und Antarktisgebiete",
                ga: "Gabun",
                gm: "Gambia",
                ge: "Georgien",
                gh: "Ghana",
                gi: "Gibraltar",
                gd: "Grenada",
                gr: "Griechenland",
                gl: "Grönland",
                gp: "Guadeloupe",
                gu: "Guam",
                gt: "Guatemala",
                gg: "Guernsey (Kanalinsel)",
                gn: "Guinea",
                gw: "Guinea-Bissau",
                gy: "Guyana",
                ht: "Haiti",
                hm: "Heard und McDonaldinseln",
                hn: "Honduras",
                hk: "Hongkong",
                in: "Indien",
                id: "Indonesien",
                im: "Insel Man",
                iq: "Irak",
                ir: "Iran",
                ie: "Irland",
                is: "Island",
                il: "Israel",
                it: "Italien",
                jm: "Jamaika",
                jp: "Japan",
                ye: "Jemen",
                je: "Jersey (Kanalinsel)",
                jo: "Jordanien",
                ky: "Kaimaninseln",
                kh: "Kambodscha",
                cm: "Kamerun",
                ca: "Kanada",
                cv: "Kap Verde",
                kz: "Kasachstan",
                qa: "Katar",
                ke: "Kenia",
                kg: "Kirgisistan",
                ki: "Kiribati",
                cc: "Kokosinseln",
                co: "Kolumbien",
                km: "Komoren",
                cd: "Kongo, Demokratische Republik",
                cg: "Kongo, Republik",
                kp: "Korea, Nord",
                kr: "Korea, Süd",
                hr: "Kroatien",
                cu: "Kuba",
                kw: "Kuwait",
                la: "Laos",
                ls: "Lesotho",
                lv: "Lettland",
                lb: "Libanon",
                lr: "Liberia",
                ly: "Libyen",
                li: "Liechtenstein",
                lt: "Litauen",
                lu: "Luxemburg",
                mo: "Macau",
                mg: "Madagaskar",
                mw: "Malawi",
                my: "Malaysia",
                mv: "Malediven",
                ml: "Mali",
                mt: "Malta",
                ma: "Marokko",
                mh: "Marshallinseln",
                mq: "Martinique",
                mr: "Mauretanien",
                mu: "Mauritius",
                yt: "Mayotte",
                mx: "Mexiko",
                fm: "Mikronesien",
                md: "Moldau",
                mc: "Monaco",
                mn: "Mongolei",
                me: "Montenegro",
                ms: "Montserrat",
                mz: "Mosambik",
                mm: "Myanmar",
                na: "Namibia",
                nr: "Nauru",
                np: "Nepal",
                nc: "Neukaledonien",
                nz: "Neuseeland",
                ni: "Nicaragua",
                nl: "Niederlande",
                ne: "Niger",
                ng: "Nigeria",
                nu: "Niue",
                mp: "Nördliche Marianen",
                mk: "Nordmazedonien",
                nf: "Norfolkinsel",
                no: "Norwegen",
                om: "Oman",
                at: "Österreich",
                tl: "Osttimor",
                pk: "Pakistan",
                ps: "Palästina",
                pw: "Palau",
                pa: "Panama",
                pg: "Papua-Neuguinea",
                py: "Paraguay",
                pe: "Peru",
                ph: "Philippinen",
                pn: "Pitcairninseln",
                pl: "Polen",
                pt: "Portugal",
                pr: "Puerto Rico",
                re: "Réunion",
                rw: "Ruanda",
                ro: "Rumänien",
                ru: "Russland",
                sb: "Salomonen",
                bl: "Saint-Barthélemy",
                mf: "Saint-Martin (franz. Teil)",
                zm: "Sambia",
                ws: "Samoa",
                sm: "San Marino",
                st: "São Tomé und Príncipe",
                sa: "Saudi-Arabien",
                se: "Schweden",
                ch: "Schweiz",
                sn: "Senegal",
                rs: "Serbien",
                sc: "Seychellen",
                sl: "Sierra Leone",
                zw: "Simbabwe",
                sg: "Singapur",
                sx: "Sint Maarten (niederl. Teil)",
                sk: "Slowakei",
                si: "Slowenien",
                so: "Somalia",
                es: "Spanien",
                lk: "Sri Lanka",
                sh: "St. Helena",
                kn: "St. Kitts und Nevis",
                lc: "St. Lucia",
                pm: "Saint-Pierre und Miquelon",
                vc: "St. Vincent und die Grenadinen",
                za: "Südafrika",
                sd: "Sudan",
                gs: "Südgeorgien und die Südlichen Sandwichinseln",
                ss: "Südsudan",
                sr: "Suriname",
                sj: "Svalbard und Jan Mayen",
                sz: "Swasiland",
                sy: "Syrien",
                tj: "Tadschikistan",
                tw: "Republik China",
                tz: "Tansania",
                th: "Thailand",
                tg: "Togo",
                tk: "Tokelau",
                to: "Tonga",
                tt: "Trinidad und Tobago",
                td: "Tschad",
                cz: "Tschechien",
                tn: "Tunesien",
                tr: "Türkei",
                tm: "Turkmenistan",
                tc: "Turks- und Caicosinseln",
                tv: "Tuvalu",
                ug: "Uganda",
                ua: "Ukraine",
                hu: "Ungarn",
                um: "United States Minor Outlying Islands",
                uy: "Uruguay",
                uz: "Usbekistan",
                vu: "Vanuatu",
                va: "Vatikanstadt",
                ve: "Venezuela",
                ae: "Vereinigte Arabische Emirate",
                us: "Vereinigte Staaten",
                gb: "Vereinigtes Königreich",
                vn: "Vietnam",
                wf: "Wallis und Futuna",
                cx: "Weihnachtsinsel",
                eh: "Westsahara",
                cf: "Zentral­afrikanische Republik",
                cy: "Zypern",
            },
            noresult: "Keine Treffer",
            next: "Weiter",
            back: "Zurück",
            close: "Schließen",
            save: "Speichern",
            create: "weiteres Suchprofil erstellen",
            profileLimitReached: "Suchprofil-Limit erreicht",
            changePassword: "Passwort ändern",
            login: "Anmelden",
            step: "Schritt",
            of: "von",
            in: "in",
            km: "km",
            false: "Nein",
            true: "Ja",
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            other: "Sonstige",
            remarks: "Anmerkungen",
            empty: "Leer",
            print: "Ausdrucken",
            loading: "Lädt",
            accept: "Akzeptieren",
            health:{
                remarks: "Anmerkungen / Spezialisierungen",
            }
        }
    },
    "de": {
        fallback: "at",
        content: {
            jobTypes: {
                pharmaingenieur: "Pharmaingenieur/-in",
                ptaintern: "PTA Praktikant/-in",
                aspirant: "Pharmaziepraktikant/-in (Praktisches Jahr)",
                trainee: "PKA Auszubildende/-r",
                //        ___           ___       __        __   ___ 
                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                //         
                health: {
                    "pfa": "Pflegefachfrau / Pflegefachmann",
                    "pfa2": "Altenpfleger/in",
                    "pfa3": "Gesundheits- und Krankenpfleger/in",
                    "bsc": "Master Pflegefachfrau / Pflegefachmann",
                    "bsc2": "Bachelor Gesundheits- und Kinderkrankenpfleger/in",
                    "pa": "Gesundheits- und Krankenpflegehelfer/in",
                    "pa2": "Gesundheits- und Kinderkrankenpflegehelfer/in",
                    "pa3": "Gesundheits- und Pflegefachassistent/in",
                },
            },  
            placeholder: {
                licenceHolder: "Selbständigkeit – Kauf/Verkauf Apotheke/-beteiligung",
            }
        }
    },
    "ch": {
        fallback: "at",
        content: {
            jobTypes: {
                aspirant: "Assistenzjahr",
                pka: "Pharma-AssistentIn",
                //        ___           ___       __        __   ___ 
                //  |__| |__   /\  |     |  |__| /  `  /\  |__) |__  
                //  |  | |___ /~~\ |___  |  |  | \__, /~~\ |  \ |___ 
                //         
                health: {
                    "pfa": "Fachfrau/-mann Gesundheit EFZ",
                    "bsc": "Bachelor of Science in Pflege FH/UH",
                    "bsc2": "Dipl. Pflegefachfrau/-mann HF",
                    "pa": "Fachfrau/-mann Betreuung EFZ",
                    "pa2": "Assistent/-in Gesundheit und Soziales EBA",
                },
            },        
            cashierSystems: {
                text: "IT Systeme",
                pharamsoftsarl: "Pharmasoft Sàrl",
                cseag: "CSE AG",
                datagest: "Datagest Sàrl",
                daufsa: "Dauf SA",
                propharma: "ProPharma Systems AG",
                pharmatic: {
                    goldengate: "Pharmatic - Golden Gate",
                    tactil: "Pharmatic - Tactil"
                },
                hci: {
                    pharma4: "HCI Solutions AG - pharma4",
                    triapharm: "HCI Solutions AG - TriaPHARM"
                },
                inphasa: "Inpha SA"
            },
            placeholder: {
                licenceHolder: "Selbständigkeit – Kauf/Verkauf Apotheke/-beteiligung",
            }
        }
    }
};
