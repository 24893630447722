import React, { Component } from 'react';
import axios from "axios";
import CenteredComponent from './centeredComponent';
import LanguageController from '../../controllers/languageController';
import AuthenticationController from '../../controllers/authenticationController';

export default class AdvertisementComponent extends Component {

    // holds complete json file data so we don't need to reload
    static data = null;

    defaultDisplayInSeconds = 12;

    constructor(props) {
        super(props);

        this.state = {
            loadedPath: null,
            bannerIndex: 0,
            bannerData: [],
            activeBanner: null
        };
    }

    componentDidMount() {

        clearInterval(this.timeout);

        //
        // loads banner data from /settings/apo-ads.json either for current route
        // or if no matching entry found from "default" json node
        //
        this.loadBannerData();
    }

    componentDidUpdate(){
        // had route changed?
        // if yes reload banner data again in case there is an explicit entry for the new route
        if ( window.location.pathname != this.state.loadedPath)
        {
            //console.log(`window.location => this.state.loadedPath`);
            //console.log(`${window.location.pathname} => ${this.state.loadedPath}`);
            clearInterval(this.timeout);
            this.loadBannerData();
        }
    }

    //
    // loads banner data from /settings/apo-ads.json either for current route
    // or if no matching entry found from "default" json node
    //
    loadBannerData() {

        if ( AdvertisementComponent.data === null )
        {
            axios.get('/settings/apo-ads.json?v=' + new Date().getTime()).then(res => {
                AdvertisementComponent.data = res.data;
                this.loadBannerRouteData();
            });
        }
        else
            this.loadBannerRouteData();
    }

    // loads the banner data for the current route from the static data cache
    loadBannerRouteData()
    {
        var route = "/" + window.location.pathname.split('/')[1];
        var dataset = null;

        //var debugExists = AdvertisementComponent.data.hasOwnProperty(route);
        //var debugDataset = AdvertisementComponent.data[route];

        //
        // explicit CountryCode dataset available?
        // now check if there is a country specific dataset underneath the root ndoe
        //
        var countryCode = LanguageController.determineLanguage();
        if ( AdvertisementComponent.data.hasOwnProperty(countryCode) )
            dataset = AdvertisementComponent.data[countryCode];
        else
            dataset = AdvertisementComponent.data["others"];

        //
        // CS 1/2/2023: implement new ad-extension which optionally supports type of user in the URI path (e.g. /profile, /profile[person], /profile[employer]) 
        //
        // get root node in /images/settings/apo-ads.json 
        // which is either the routename (/login, /logout), optionally with the user type (/profile[person], /profile[employer]) 
        // and if they all don't exists default back to 'default'
        //
        if ( AuthenticationController.isAuthenticated() ){
            //
            // AUTHENTICATED USER
            //
            var auth_route = route + (AuthenticationController.isPerson() ? "[person]" : "[employer]");

            if ( dataset.hasOwnProperty(auth_route) )
                dataset = dataset[auth_route];
            else
            {
                // try only uri path now
                if ( dataset.hasOwnProperty(route) )
                    dataset = dataset[route];
                else
                {
                    var default_route = "default" + (AuthenticationController.isPerson() ? "[person]" : "[employer]");
                    if ( dataset.hasOwnProperty(default_route) )
                        dataset = dataset[default_route];
                    else
                        dataset = dataset["default"];
                }
            }
        }
        else{
            //
            // ANONYMOUS USER
            //
            if ( dataset.hasOwnProperty(route) )
                dataset = dataset[route];
            else
                dataset = dataset["default"];
        }

        if ( dataset.length > 0 )
        {
            this.setState({loadedPath: window.location.pathname, bannerData: dataset, activeBanner: dataset[0], bannerIndex:0});
            this.rotateToBannerWithIndex(0);
        }
    }
        
    rotateToBannerWithIndex(index) {

        if ( this.state.bannerData.length == 0 ) return;
        if ( index > this.state.bannerData.length - 1) index = 0; // reset if index too large

        if ( this.state.bannerIndex != index )
            this.setState({bannerIndex:index, activeBanner: this.state.bannerData[index]});

        // if there are more than just one banner for the current route set timeouts to rotate
        if ( this.state.bannerData.length > 1)
        {
            var displaySeconds = this.defaultDisplayInSeconds; // defaults to 12 seconds
            if ( this.state.activeBanner && this.state.activeBanner.hasOwnProperty('displaySeconds'))
            displaySeconds = this.state.activeBanner.displaySeconds;
            this.timeout = setTimeout(() => {this.rotateToBannerWithIndex(index+1)}, displaySeconds * 1000);
        }
    }

    getActiveBannerHtml() {

        if ( this.state.activeBanner !== null && typeof this.state.activeBanner !== 'undefined')
        {
            if ( this.state.activeBanner.hasOwnProperty('html'))
                return this.state.activeBanner.html.join("");
            else
                return <h1>YOUR AD HERE</h1>;
        }
        else
            return <h1>YOUR AD HERE</h1>;

    }

    render() {

        return (
            <div className="text-center" dangerouslySetInnerHTML={{ __html: this.getActiveBannerHtml() }} />
            /*
                <div className="text-center">
                    {this.getActiveBannerHtml()}
                </div>
            */
        );
    }

}