import React, { Component } from 'react';
import { Form, Button, FormGroup, Input, FormFeedback } from 'reactstrap';
import { Formik, Field } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import CenteredComponent from '../_components/centeredComponent';

import SecurityController from '../../controllers/securityController';
import LoadingController from '../../controllers/loadingContoller';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import LanguageController, { _ } from '../../controllers/languageController';
import { InputForm } from '../_components/inputFormComponent';
import AuthenticationController from '../../controllers/authenticationController';
import ConfigController from '../../controllers/configController';

export default class Login extends Component {

    onSubmit(fields) {

        //console.log(`Login: ${fields.email}/${fields.password}`);
        
        LoadingController.show();
        ApiController.login(fields.email, fields.password, callbackWrapper((response) => {

            if (response.data.error) {
                if (response.data.error.message === "error.emailNotVerified") {
                    this.props.history.push("/activation/" + SecurityController.temp_encrypt({ email: fields.email, accountId: response.data.error.data }));
                    return;
                }

                NotificationController.pushError(_(response.data.error.message), _("error.loginFailed"));
                return;
            }

            AuthenticationController.setToken(response.data.result);
            this.props.history.push("/");
        }));
    }

    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(_("invalid.email"))
                .required(_("no.email")),
            password: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password")),
        });

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                {ConfigController.isPharmacyEdition() && 
                    <h1 className="apo-login-header mb-2">{_("text.welcomeTo")} <a className="apo-link" href="https://apopersonal.com" target="_blank" data-toggle="tooltip" data-placement="top" title="Startseite aufrufen"><span className="text-success">Apo</span>personal</a>.</h1>
                }
                {ConfigController.isHealthcareEdition() && 
                    <h1 className="apo-login-header mb-2">{_("text.welcomeTo")} <a className="apo-link" href="https://pflege-finden.com" target="_blank" data-toggle="tooltip" data-placement="top" title="Startseite aufrufen"><span className="pflege-orange">pflege</span><span className="pflege-grey">-finden</span></a>.</h1>
                }
                <h3 className="mb-4 offset-lg-1 col-lg-10">{_("text.login.subtitle")}</h3>
                <Formik initialValues={{ email: "", password: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="offset-lg-2 col-lg-8 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.email")} name="email" type="email" inputmode="email" component={InputForm} />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.password")} name="password" type="password" component={InputForm} />
                            </FormGroup>

                            <div className="row">
                                <div className="col-md-6 text-center m-auto">
                                    <h5 className="mt-3 mt-md-0 col-12"><Link className="apo-link" to={"/reset"}>{_("text.resetPassword")}</Link></h5>
                                    <h5 className="mt-3 mt-md-0 col-12"><Link className="apo-link" to={"/register"}>{_("nav.registerAsNew")}</Link></h5>
                                </div>
                                <div className="col-md-6 order-first order-md-2 text-right">
                                    <Button type="submit" className="col-12" color="success" disabled={props.isSubmitting}>{_("login")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }

}

