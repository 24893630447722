import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import CenteredComponent from '../centeredComponent';
import { Form, Button, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import { InputForm } from '../inputFormComponent';
import SelectInputComponent, { optionFilter } from '../selectInputComponent';
import * as Yup from "yup";
import LanguageController from '../../../controllers/languageController';

export default class Step5Component extends Component {

    componentWillMount() {
        this.setState({ submitted: false, newsletter: false });
        window.document.getElementById('root').scrollIntoView({
            behavior: 'smooth'
        })
    }

    submit() {
        if (!this.state.submitted) {
            this.setState({ submitted: true });
        }
    }

    onSubmit(fields) {
        if (!this.state.terms) {
            return;
        }

        if (this.props.type === "person" && (!this.state.nationality || !this.state.gender || !this.state.canWork))
            return;

        this.props.next({
            firstname: fields.firstname,
            lastname: fields.lastname,
            phone: fields.phone,
            password: fields.password,
            email: fields.email,
            companyName: fields.companyName,
            address: fields.address,
            newsletter: this.state.newsletter,
            nationality: this.state.nationality,
            gender: this.state.gender
        });
    }

    hasWorkPermission(value) {
        this.setState({ canWork: value });
    }

    acceptTerms(value) {
        this.setState({ terms: value });
    }

    allowNewsletter(value) {
        this.setState({ newsletter: value });
    }

    retrieveGender() {
        return [{ id: 0, item: _("gender.other") }, { id: 1, item: _("gender.female") }, { id: 2, item: _("gender.male") }];
    }

    retrieveNationalities() {
        if (this.nationalities) {
            return this.nationalities;
        }

        this.nationalities = ConfigController.retrieveNationalities();
        return this.nationalities;
    }

    selectGender(value) {
        this.setState({ gender: value });
    }

    selectNationality(value) {

        // CS 4/7/22: find out if currently selected country is an EWR or not
        var targetCountryIsEWR = LanguageController.determineLanguageCountryEWR();
        if ( !targetCountryIsEWR)
            this.setState({ nationality: value, canWork: true });   // permission to work by default for non EWR countries (e.g. CH)
        else
            this.setState({ nationality: value, canWork: value.ewr });
    }

    render() {

        var titleCode = "text.register.step5";
        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        if (isHealthcareEdition) titleCode = "text.register.health.step5";

        // CS 4/7/22: find out if currently selected country is an EWR or not
        var selectedCountryIsEWR = LanguageController.determineLanguageCountryEWR();


        if (this.props.type == "person") {

            const validationSchema = Yup.object().shape({
                email: Yup.string()
                    .email(_("invalid.email"))
                    .required(_("no.email")),
                password: Yup.string()
                    .min(6, _("invalid.password"))
                    .required(_("no.password")),
                passwordConfirm: Yup.string()
                    .min(6, _("invalid.password"))
                    .required(_("no.password"))
                    .oneOf([Yup.ref("password"), null], _("mismatch.password")),
                firstname: Yup.string()
                    .min(2, _("invalid.name"))
                    .required(_("no.name")),
                lastname: Yup.string()
                    .min(2, _("invalid.name"))
                    .required(_("no.name"))
            });

            return (
                <CenteredComponent size={12} className="animated fadeIn">
                    <h1 className="mb-2">{_("step")} 5 {_("of")} 5</h1>
                    <h4 className="mb-0 offset-lg-1 col-lg-10">{_(`${titleCode}.${this.props.type}`)}</h4>
                    <h4 className="mb-4 offset-lg-1 col-lg-10">{_(`${titleCode}.activationNote`)}</h4>
                    <Formik initialValues={{ email: "", password: "", passwordConfirm: "", firstname: "", lastname: "", phone: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                        render={(props) => (
                            <Form noValidate className="offset-lg-2 col-lg-8 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.firstname")} name="firstname" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.lastname")} name="lastname" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <SelectInputComponent
                                        onSelect={this.selectGender.bind(this)}
                                        onFilter={optionFilter(this.retrieveGender())}
                                        submitted={this.state.submitted}
                                        placeholder={_("placeholder.gender")} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <SelectInputComponent
                                        onSelect={this.selectNationality.bind(this)}
                                        onFilter={optionFilter(this.retrieveNationalities())}
                                        submitted={this.state.submitted}
                                        placeholder={_("placeholder.nationality")} />
                                </FormGroup>

                                {/* // CS 4/7/22: only ask for work permission if country is an EWR member */}
                                {selectedCountryIsEWR && this.state.nationality && !this.state.nationality.ewr && (
                                    <div className="custom-control custom-checkbox mb-2 text-left">
                                        <input className="custom-control-input" id="nationality" type="checkbox" onChange={(e) => this.hasWorkPermission(e.target.checked)} value={this.state.canWork} />
                                        <label className={`custom-control-label noselect ${this.state.submitted && !this.state.canWork && "text-warning"}`} htmlFor="nationality">{_("text.hasWorkPermission")}</label>
                                    </div>
                                )}

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.phoneNumber")} name="phone" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.email")} name="email" type="email" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.enterPassword")} name="password" type="password" component={InputForm} />
                                </FormGroup>

                                <FormGroup className="mb-2">
                                    <Field placeholder={_("placeholder.passwordConfirm")} name="passwordConfirm" type="password" component={InputForm} />
                                </FormGroup>

                                <h5 className="my-4">{_("text.registerInformation")}</h5>

                                <div className="custom-control custom-checkbox mb-2 text-left">
                                    <input className="custom-control-input" id="terms" type="checkbox" onChange={(e) => this.acceptTerms(e.target.checked)} value={this.state.terms} />
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} 
                                        {isPharmacyEdition && 
                                            <a href="https://www.apopersonal.com/agb" target="_blank" className='ml-1'>{_("text.showTerms")}</a>
                                        }
                                        {isHealthcareEdition && 
                                            <a href="https://www.pflege-finden.com/agb" target="_blank" className='ml-1'>{_("text.showTerms")}</a>
                                        }
                                    </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-3 text-left">
                                    <input className="custom-control-input" id="newsletter" type="checkbox" onChange={(e) => this.allowNewsletter(e.target.checked)} value={this.state.newsletter} />
                                    <label className="custom-control-label noselect" htmlFor="newsletter">{_("text.acceptNewsletter")}</label>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 m-0 py-2">
                                        <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                    </div>
                                    <div className="offset-md-6 col-md-6 text-right m-0 py-2">
                                        <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("next")} <i className="fas fa-angle-right"></i></Button>
                                    </div>
                                </div>
                            </Form>
                        )}>
                    </Formik>
                </CenteredComponent>
            );
        }

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(_("invalid.email"))
                .required(_("no.email")),
            password: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password")),
            passwordConfirm: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password"))
                .oneOf([Yup.ref("password"), null], _("mismatch.password")),
            companyName: Yup.string()
                .min(3, _("invalid.companyName"))
                .required(_("no.companyName")),
            address: Yup.string()
                .min(3, _("invalid.address"))
                .required(_("no.address"))
        });

        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("step")} 5 {_("of")} 5</h1>
                <h4 className="mb-0 offset-lg-1 col-lg-10">{_(`${titleCode}.${this.props.type}`)}</h4>
                <h4 className="mb-4 offset-lg-1 col-lg-10">{_(`${titleCode}.activationNote`)}</h4>
                <Formik initialValues={{ email: "", password: "", passwordConfirm: "", companyName: "", address: "", phone: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                    render={(props) => (
                        <Form noValidate className="offset-lg-2 col-lg-8 mb-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>

                            <FormGroup className="mb-2">
                                {isPharmacyEdition && 
                                    <Field placeholder={_("placeholder.companyName")} name="companyName" component={InputForm} />
                                }
                                {isHealthcareEdition && 
                                    <Field placeholder={_("placeholder.health.companyName")} name="companyName" component={InputForm} />
                                }
                            </FormGroup>

                            {/* readonly area name from step-1 */}
                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.postalArea")} name="postalarea" component={InputForm} value={this.props.state[1].area.item} readOnly="readOnly" />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.address")} name="address" component={InputForm} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                {isPharmacyEdition && 
                                   <Field placeholder={_("placeholder.phone")} name="phone" component={InputForm} />
                                }
                                {isHealthcareEdition && 
                                   <Field placeholder={_("placeholder.health.phone")} name="phone" component={InputForm} />
                                }
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.email")} name="email" type="email" component={InputForm} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.password")} name="password" type="password" component={InputForm} />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Field placeholder={_("placeholder.passwordConfirm")} name="passwordConfirm" type="password" component={InputForm} />
                            </FormGroup>

                            <div className="custom-control custom-checkbox mb-2 text-left">
                                <input className="custom-control-input" id="terms" type="checkbox" onChange={(e) => this.acceptTerms(e.target.checked)} value={this.state.terms} />
                                {isPharmacyEdition && 
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} <a href="https://www.apopersonal.com/agb" target="_blank">{_("text.showTerms")}</a></label>
                                }
                                {isHealthcareEdition && 
                                    <label className={`custom-control-label noselect ${this.state.submitted && !this.state.terms && "text-warning"}`} htmlFor="terms">{_("text.acceptTermsInternal")} <a href="https://www.pflege-finden.com/agb" target="_blank">{_("text.showTerms")}</a></label>
                                }
                            </div>

                            <div className="custom-control custom-checkbox mb-3 text-left">
                                <input className="custom-control-input" id="newsletter" type="checkbox" onChange={(e) => this.allowNewsletter(e.target.checked)} value={this.state.newsletter} />
                                <label className="custom-control-label noselect" htmlFor="newsletter">{_("text.acceptNewsletter")}</label>
                            </div>

                            <div className="row">
                                <div className="col-md-6 m-0">
                                    <Button type="button" className="col-12" color="danger" onClick={(x) => this.props.back()}><i className="fas fa-angle-left"></i> {_("back")}</Button>
                                </div>
                                <div className="offset-md-6 col-md-6 text-right m-0">
                                    <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("next")} <i className="fas fa-angle-right"></i></Button>
                                </div>
                            </div>
                        </Form>
                    )}>
                </Formik>
            </CenteredComponent>
        );
    }
}