import axios from "axios";

import LanguageController, { _ } from "./languageController";
import NotificationController from "./notificationController";
import LoadingController from "./loadingContoller";
import AuthenticationController from "./authenticationController";
import ConfigController from "./configController";

/* const endpoint = "http://api.apopersonal.com/"; */
//const endpoint = "http://88.198.165.155/";        /* Islam Test server */
//const endpoint = "http://192.168.1.99:5050/";    /* CS: VM:5050*/
//const endpoint = "http://apopersonalwin-staging.azurewebsites.net/";    /* Azure staging */

//
// CS: dynamically determine the API endpoint for 'development', 'test' and 'production' environment
//
    if ( process.env.NODE_ENV !== "production")
    {
        //   __   ___          ___           
        //  |  \ |__  \  /    |__  |\ | \  / 
        //  |__/ |___  \/     |___ | \|  \/  
        //                                   
        // DEV: http://192.168.1.99:5050/
        // APO-TEST (AZURE STAGING): https://apopersonalwin-staging.azurewebsites.net/
        // APO-PRODUCTION (AZURE): https://apopersonalwin.azurewebsites.net/
        // HEALTH-TEST (AZURE STAGING): https://healthcareapp-staging.azurewebsites.net/
        // HEALTH-PRODUCTION (AZURE): https://healthcareapp.azurewebsites.net/

        var endpoint = "http://192.168.1.99:5050/"; // win10-dev-vm
        //var endpoint = "https://apopersonalwin-staging.azurewebsites.net/"
        //var endpoint = "https://healthcareapp-staging.azurewebsites.net/"
        //window['runtimeConfig'].edition = 2;    // APO=1, HealthCare=2
        //window['runtimeConfig'].title = 'App | Pflege-finden'
    }

// CS 16/12/21: load api endpoint at runtime so that we can set up various envirnments (production, test) with different backends
if ( process.env.NODE_ENV === "production") endpoint = window['runtimeConfig'].apiUrl;   // load at runtime from /settings/runtime-config.js

//
// CS 16/12/21: load api endpoint from configuration file
//

const url = (path) => endpoint + path;

//
// CS 25/4/22: dynamic title for each edition controlled by runtime-config.js
//
if (window['runtimeConfig'].title) document.title = window['runtimeConfig'].title
// CS 25/4/22: set new metadata desc for edition - pointless though
//document.querySelector('meta[name="description"]').setAttribute("content", "Test");

//console.log(`API-ENDPOINT: ${endpoint}`)

const catchError = (error, callback) => {
    if (!error.response || [200, 400, 401, 403].indexOf(error.response.status) === -1) {
        if (ConfigController.retrieveConfiguration()) {
            NotificationController.pushError(_("error.networkNotAvailable.message"), _("error.networkNotAvailable.title"));
        }
        callback({ preCatched: true });
    } else {
        callback(error.response);
    }
};

const del = (path, callback) => {
    let header = undefined;
    if (AuthenticationController.isAuthenticated()) {
        header = { Authorization: `Bearer ${AuthenticationController.retrieveToken()}` };
    }

    axios.delete(url(path), { headers: header })
        .then(callback)
        .catch((error) => catchError(error, callback));
}

const get = (path, callback) => {
    let header = undefined;
    if (AuthenticationController.isAuthenticated()) {
        header = { Authorization: `Bearer ${AuthenticationController.retrieveToken()}` };
    }

    axios.get(url(path), { headers: header })
        .then(callback)
        .catch((error) => catchError(error, callback));
}

const post = (path, data, callback) => {
    let header = undefined;
    if (AuthenticationController.isAuthenticated()) {
        header = { Authorization: `Bearer ${AuthenticationController.retrieveToken()}` };
    }

    axios.post(url(path), data, { headers: header })
        .then(callback)
        .catch((error) => catchError(error, callback));
}

const postFile = (path, file, callback) => {
    let header = undefined;
    if (AuthenticationController.isAuthenticated()) {
        header = { Authorization: `Bearer ${AuthenticationController.retrieveToken()}` };
    }

    header["Content-Type"] = "multipart/form-data";
    axios.post(url(path), file, { headers: header })
        .then(callback)
        .catch((error) => catchError(error, callback));
}

const put = (path, data, callback) => {
    let header = undefined;
    if (AuthenticationController.isAuthenticated()) {
        header = { Authorization: `Bearer ${AuthenticationController.retrieveToken()}` };
    }

    axios.put(url(path), data, { headers: header })
        .then(callback)
        .catch((error) => catchError(error, callback));
}

const ApiController = {

    callbackWrapper(callback) {
        return (response) => {
            try {
                if ([200, 400].indexOf(response.status) !== -1) { // ok / badRequest
                    callback(response);
                    return;
                }

                if ([401, 403].indexOf(response.status) !== -1) { // unauthorized / forbidden
                    NotificationController.pushError(_("error.notAllowed"))
                    AuthenticationController.logout();
                    (this.history || this.props.history).push("/");
                    return;
                }

                if (!response.preCatched) {
                    NotificationController.pushError(_("error.tryAgain"), _("error.unknownError"));
                }
            } finally {
                LoadingController.hide();
            }
        };
    },

    // CS 30 June 2020: returns EndPoint configuration
    apiEndPoint(){
        return endpoint;
    },

    adminDeleteAccount(id, callback) {
        del("admin/" + id, callback);
    },

    // CS 21/12/21: admin requests for impersonating an account (person, pharmacy)
    adminImpersonateAccount(id, callback) {
        get("admin/impersonate/" + id, callback);
    },

    // CS 21/12/21: admin requests for impersonating an account (person, pharmacy)
    adminTerminateImpersonation(callback) {
        get("admin/terminateImpersonation/", callback);
    },

    // CS 8/11/21: admin manually activating another users inactive account
    adminActivateAccount(id, callback) {
        get("admin/activate/" + id, callback);
    },

    adminRetrieveAllPersons(callback) {
        get("admin/persons", callback);
    },

    adminRetrieveSinglePerson(accountId, callback) {
        get("admin/person/" + accountId, callback);
    },

    adminRetrieveAllPharmacies(callback) {
        get("admin/pharmacies", callback);
    },

    adminRetrieveSinglePharmacy(accountId, callback) {
        get("admin/pharmacy/" + accountId, callback);
    },

    adminRetrieveAllPharmacyProfiles(callback) {
        get("admin/pharmacyProfiles", callback);
    },

    // CS 23/9/21: added new backend api call which returns various counters grouped by countried
    retrieveCountryStats(callback) {
        get("stats/CountryCounters", callback);
    },

    deleteAccount(callback) {
        del("account", callback);
    },

    verify(token, callback) {
        get(`account/activate/${token}`, callback);
    },

    mandatoryChange(id, data, callback) {
        post(`mandatoryChange/update/${id}`, data, callback);
    },

    retrievePendingMandatoryChanges(callback) {
        get("mandatoryChange", callback);
/*         
        get("mandatoryChange", (response) => {
            console.debug("retrievePendingMandatoryChanges.response below");
            console.debug(response);
            callback(response);
        });
*/    
    },

    retrieveContactInformations(profileId, otherProfileId, callback) {
        get(`contact/profile/${profileId}/${otherProfileId}`, callback);
    },

    toggleBlacklist(profileId, otherProfileId, callback) {
        post(`contact/blacklist/${profileId}/${otherProfileId}`, {}, callback);
    },

    updateProfile(data, callback) {
        put("profile", data, callback);
    },

    retrieveProfile(profileId, callback) {
        get(`profile/${profileId}`, callback);
    },

    createProfile(data, callback) {
        post("profile", data, callback);
    },

    retrieveAccountNotifications(callback) {
        get("account/notifications", callback);
    },

    updateNotifications(data, callback) {
        put("account/notifications", data, callback);
    },

    updateAccount(data, callback) {
        put("account", data, callback);
    },

    updatePersonAccount(data, callback) {
        put("account/person", data, callback);
    },

    updatePharmacyAccount(data, callback) {
        put("account/pharmacy", data, callback);
    },

    updatePassword(data, callback) {
        put("account/password", data, callback);
    },

    retrieveFiles(callback) {
        get("document", callback);
    },

    retrieveProfileFiles(profileId, otherProfileId, callback) {
        get(`document/profile/${profileId}/${otherProfileId}`, callback);
    },

    deleteFile(fileId, callback) {
        del(`document/${fileId}`, callback);
    },

    uploadFile(type, file, callback) {
        let formData = new FormData();
        formData.append("documentType", type);
        formData.append("file", file);

        postFile("document", formData, callback);
    },

    requestContact(profileId, otherProfileId, callback) {
        post(`contact/profile/${profileId}/${otherProfileId}`, {}, callback);
    },

    acceptContact(profileId, otherProfileId, callback) {
        post(`contact/profile/${profileId}/${otherProfileId}/accept`, {}, callback);
    },

    deleteContact(profileId, otherProfileId, callback) {
        del(`contact/profile/${profileId}/${otherProfileId}`, callback);
    },

    retrieveItems(callback) {
        get("item", callback);
    },

    retrieveAccountPerson(callback) {
        get("account/person", callback);

/*         get("account/person", (response) => {
            console.debug("retrieveAccountPerson.response below");
            console.debug(response);
            callback(response);
        });
 */
    },

    retrieveAccountPharmacy(callback) {
        get("account/pharmacy", callback);
    },

    retrieveProfiles(callback) {
        get("profile", callback);
    },

    activateProfile(profileId, callback) {
        get(`profile/${profileId}/activate`, callback);
    },

    deactivateProfile(profileId, callback) {
        get(`profile/${profileId}/deactivate`, callback);
    },

    deleteProfile(profileId, callback) {
        del(`profile/${profileId}`, callback);
    },

    retrieveMatching(profileId, replacement, offset, limit, callback) {
        get(`match/profile/${profileId}?replacement=${replacement}&offset=${offset}&limit=${limit}`, callback);
    },

    retrieveMatch(profileId, otherProfileId, callback) {
        get(`match/profile/${profileId}/${otherProfileId}`, callback);
    },

    searchPostalArea(value, callback) {
        get(`country/${LanguageController.determineLanguageCountryId()}/postal/search/${value}?limit=30`, callback);
    },

    // CS 15/7/20: added new more generic postal code search for variable country
    searchPostalAreaInCountry(countryID, value, callback) {
        get(`country/${countryID}/postal/search/${value}?limit=5`, callback);
    },

    retrievePostalArea(value, callback) {
        get(`country/postal/${value}`, callback);
    },

    login(email, password, callback) {
        post("account/login", { email, password }, callback);
    },

    resetPassword(email, callback) {
        post("account/password/reset", { email }, callback);
    },

    resendActivationMail(accountId, callback) {
        post("account/activate/resend", { accountId }, callback);
    },

    registerPerson(data, callback) {
        post("account/person", data, callback);
    },

    registerPharmacy(data, callback) {
        post("account/pharmacy", data, callback);
    }

}

export default ApiController;
export var callbackWrapper = ApiController.callbackWrapper;
export var apiEndpoint = url;