import React, { Component } from 'react';
import { FormFeedback } from 'reactstrap';
import InputComponent from './inputComponent';
import { _ } from '../../controllers/languageController';
import { Position } from './positionComponent';
import ApiController from '../../controllers/apiController';

const filters = {

    postalAreaFilter(query, callback) {

        // CS Aug 2021: added bail out for empty, null or too short queries
        if (!query || query.trim() === "" || query.length < 3) {
            let suggestions = [];
            callback(suggestions);
            return;
        }

        // original code
        if (!query || query.trim() === "" || query.length < 3) {
            query = " ";
        }

        ApiController.searchPostalArea(query, (response) => {
            let suggestions = [];
            // Cs 30/12/21: removed sort and made it backend's responsibility
            // if (response.data.result) {
            //     //suggestions = response.data.result.items.map(x => { return { id: x.id, item: `${x.code} ${x.name}` } });
            //     var sorted = response.data.result.items.sort(
            //         function (a,b){
            //             if ( a.name == b.name)
            //             {
            //                 if ( b.code == a.code ) return 0;
            //                 if ( a.code < b.code ) return -1;
            //                 return 1;
            //             }
            //             if ( a.name < b.name ) return -1;
            //             return 1;
            //         }
            //     );
            if (response.data.result) suggestions = response.data.result.items.map(x => { return { id: x.id, item: `${x.code} ${x.name}` } });

            callback(suggestions);
        });
    },

    // CS 20/10/2021: changed filter algorithm to be case insensitive
    // optionFilter(options) {
    //     return (query, callback) => {
    //         callback(options.filter(x => x.item.includes(query)));
    //     };
    // }
    optionFilter(options) {
        return (query, callback) => {
            callback(options.filter(x => x.item.toLowerCase().includes(query.toLowerCase())));
        };
    }

};

export var postalAreaFilter = filters.postalAreaFilter;
export var optionFilter = filters.optionFilter;

export default class SelectInputComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            query: "",
            selected: undefined,
            touched: false,
            inputmode: this.props.inputmode || "none",
            items: [],
            input_timeout: null   // CS Aug 2021: introduced timer for preventing immediate API calls for each keystroke
        };
    }

    componentWillMount() {
        this.handleChange("");
        //if (this.props.default) this.selectItem(this.props.default);
        // CS 25/1/22: prioritize the new VALUE property if it is set - this one can be changed outside by parent and the change 
        // will be reflected immediately in this new version of the component
        if (this.props.value) this.selectItem(this.props.value);
        if (!this.props.value && this.props.default) this.selectItem(this.props.default);
    }

    //
    // CS 25/1/22: added (in later react verion deprecated) event for when props have changed outside by consuming parent - in particular the new VALUE prop
    //
    componentWillReceiveProps (newProps) {

        // prop default has changed by parent -> reflect the changed value immediately
        if( newProps.value !== this.props.value) this.selectItem(newProps.value);
    }    

    handleFocus(e) {
        e.stopPropagation();
        if (this.props.disabled) {
            return;
        }

        if (this._timeoutID) clearTimeout(this._timeoutID);
        if (this.input_timeout) clearTimeout(this.input_timeout);

        if (!this.state.isOpen) {
            if (this.state.popper) {
                this.state.popper.update();
            }

            this.setState({ isOpen: true });
        }
    }

    handleBlur(e) {
        e.stopPropagation();
        if (this._timeoutID) clearTimeout(this._timeoutID);
        if (this.input_timeout) clearTimeout(this.input_timeout);

        this._timeoutID = setTimeout(() => {
            if (this.state.isOpen) {
                this.setState({ isOpen: false, query: "", touched: true });
            }
        }, 150);
    }

    handleChange(value) {
        this.setState({ query: value, selected: undefined });

        //
        // CS Aug 2021: implemented timout to prevent from API being attacked with a call for each keystroke
        //
        if (this.input_timeout) clearTimeout(this.input_timeout);

        this.input_timeout = setTimeout(() => {

            if (this.props.onFilter) {
                this.props.onFilter(value, ((result) => {
                    this.setState({ items: result || [] });
                    if (this.state.popper) {
                        this.state.popper.update();
                    }
                }).bind(this));
            }

        }, 500);

    }

    selectItem(item) {

        this.setState({ selected: item, query: "", isOpen: false });

        if (this.props.onSelect) this.props.onSelect(item);
    }

    retrieveItems(selectItem) {

        if (this.state.items.length <= 0)
            return (<a className="dropdown-item noselect">{_("noresult")}</a>);

        return this.state.items.map(x => (
            <li key={x.id}>
                <a className="dropdown-item noselect" onClick={() => {
                    selectItem(x);
                }}>
                    {x.item}
                </a>
            </li>
        ));
    }

    registerPopper(popper) { this.setState({ popper }); }

    retrieveValue() {

        if (this.state.query.length > 0) return this.state.query;

        if (this.state.selected) return this.state.selected.item;

        return "";
    }

    isInvalid() {

        return (this.props.required || this.props.submitted)
            && (this.state.touched || this.props.submitted)
            && !this.state.selected;
    }

    render() {

        return (
            <div onFocus={this.handleFocus.bind(this)} onBlur={this.handleBlur.bind(this)}>
                <Position
                    callback={{
                        selectItem: this.selectItem.bind(this),
                        retrieveItems: this.retrieveItems.bind(this),
                        handleFocus: this.handleFocus.bind(this),
                        handleBlur: this.handleBlur.bind(this)
                    }}
                    registerPopper={this.registerPopper.bind(this)}
                    parent={
                        <>
                            <InputComponent
                                className={`${this.props.shadow ? "shadow-readonly" : ""} ${this.props.className}`}
                                placeholder={this.props.placeholder || _("dropdownMenu")}
                                value={this.retrieveValue()}
                                invalid={this.isInvalid()}
                                inputmode={this.state.inputmode}
                                disabled={this.props.disabled ? "disabled" : ""}
                                onChange={((e) => this.handleChange(e.target.value)).bind(this)} />
                            {this.isInvalid() && <FormFeedback className="pb-1 display-block">{_("invalid.selection")}</FormFeedback>}
                        </>
                    }
                    target={(style, callback) => (
                        <div style={style}
                            tabIndex="-1" onFocus={callback.handleFocus} onBlur={callback.handleBlur}
                            className={`dropdown-menu card my-1 ${(this.state.isOpen) ? "d-block" : "d-none"}`}>
                            <div className="card-body py-1 px-0">
                                {callback.retrieveItems(callback.selectItem)}
                            </div>
                        </div>
                    )}
                    options={{
                        placement: 'bottom-start'
                    }}
                />
            </div>
        );
    }

}

