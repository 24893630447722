import { store } from 'react-notifications-component';
import { _ } from './languageController';

const NotificationController = {

    push(type, message, title) {
        store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true
            }
        });
    },

    pushError(message, title) {
        this.push("danger", message, title || _("notification.error"));
    },

    pushSuccess(message, title) {
        this.push("success", message, title || _("notification.success"));
    },

    pushInfo(message, title) {
        this.push("info", message, title || _("notification.info"));
    }

}

export default NotificationController;