import React, { Component } from 'react';

import CenteredComponent from '../_components/centeredComponent';
import { _ } from '../../controllers/languageController';
import Step1Component from '../_components/registration/step1Component';
import Step2Component from '../_components/registration/step2Component';

import SelectTypeComponent from '../_components/registration/selectTypeComponent';
import Step3Component from '../_components/registration/step3Component';
import Step4Component from '../_components/registration/step4Component';
import Step5Component from '../_components/registration/step5Component';
import LoadingController from '../../controllers/loadingContoller';
import ApiController, { callbackWrapper } from '../../controllers/apiController';
import NotificationController from '../../controllers/notificationController';
import ConfigController from '../../controllers/configController';

export default class Register extends Component {

    componentWillMount() {

        this.setState({ step: 1, content: [] });

        if (!this.props.match.params.type || ["person", "pharmacy"].indexOf(this.props.match.params.type) === -1) {
            // noop
        } else {
            this.setState({ type: this.props.match.params.type });
        }
    }

    onCommit() {

        var isPharmacyEdition = ConfigController.isPharmacyEdition();
        var isHealthcareEdition = ConfigController.isHealthcareEdition();

        let accountRegister = {
            email: this.state.content[5].email,
            phone: this.state.content[5].phone || "",
            newsletter: this.state.content[5].newsletter || false,
            password: this.state.content[5].password,
            terms: true,
            postalAreaId: this.state.content[1].area.id
        };

        let createProfile = {

            jobId: this.state.content[1].jobType.id,
            maximumDistancetoWork: Math.min(1000, this.state.content[1].distance),
            hoursPerWeek: this.state.content[2].hoursPerWeek,
            availableInWeeks: this.state.content[2].availableIn,
            availableForNightlyShifts: isPharmacyEdition ? this.state.content[2].nightlyShift.id : false,
            availableAsReplacement: this.state.content[2].replacement.id,

            // added 2 new properties
            availableForOverpay: this.state.content[2].overpay.id,
            accomodation: isPharmacyEdition ? this.state.content[2].accomodation.id : false,
            // CS 24/9/21: added 2 new properties management and licenceHolder
            management: this.state.content[2].management.id,
            licenceHolder: isPharmacyEdition ? this.state.content[2].licenceHolder.id : false,
            // CS 15/10/21: added new property CovidTester
            covidTester: isPharmacyEdition ? this.state.content[2].covidTester.id : false,
            // CS 27/6/22: added new property [ProfileModel].LindaFlag in step 4
            lindaFlag: this.state.content[4].lindaFlag.id,
            // CS 29/9/22: added new property [ProfileModel].FlexibleCalendarWeek in step 2
            flexibleCalendarWeek: isPharmacyEdition ? this.state.content[2].flexibleCalendarWeek.id : false,

            otherLanguage: this.state.content[3].otherText.languages || "",
            otherCashierSystem: this.state.content[3].otherText.cashierSystems || "",
            otherAdminSkill: this.state.content[4].otherText.admin || "",
            otherCustomerSkill: this.state.content[4].otherText.customer || "",
            otherLogisticSkill: this.state.content[4].otherText.logistic || "",
            otherManufacturSkill: this.state.content[4].otherText.manufactur || "",
            otherMarketingSkill: this.state.content[4].otherText.marketing || "",
            remarks: this.state.content[4].profileRemarks,  // CS 28/9/21: added support for new field Profile.Remarks
            shifts: [
                { dayOfWeek: 0, shiftId: this.state.content[2].monday.id }, { dayOfWeek: 1, shiftId: this.state.content[2].tuesday.id },
                { dayOfWeek: 2, shiftId: this.state.content[2].wednesday.id }, { dayOfWeek: 3, shiftId: this.state.content[2].thursday.id },
                { dayOfWeek: 4, shiftId: this.state.content[2].friday.id }, { dayOfWeek: 5, shiftId: this.state.content[2].saturday.id },
                { dayOfWeek: 6, shiftId: this.state.content[2].sunday.id },
                // alternate weekdays
                { dayOfWeek: 20, shiftId: this.state.content[2].altMonday.id }, { dayOfWeek: 21, shiftId: this.state.content[2].altTuesday.id },
                { dayOfWeek: 22, shiftId: this.state.content[2].altWednesday.id }, { dayOfWeek: 23, shiftId: this.state.content[2].altThursday.id },
                { dayOfWeek: 24, shiftId: this.state.content[2].altFriday.id }, { dayOfWeek: 25, shiftId: this.state.content[2].altSaturday.id },
                { dayOfWeek: 26, shiftId: this.state.content[2].altSunday.id }
            ],
            languages: Object.keys(this.state.content[3].selected.languages || {}),
            cashierSystems: Object.keys(this.state.content[3].selected.cashierSystems || {}),
            adminSkills: Object.keys(this.state.content[4].selected.admin || {}),
            customerSkills: Object.keys(this.state.content[4].selected.customer || {}),
            logisticSkills: Object.keys(this.state.content[4].selected.logistic || {}),
            manufacturSkills: Object.keys(this.state.content[4].selected.manufactur || {}),
            marketingSkills: Object.keys(this.state.content[4].selected.marketing || {}),
            // CS 9/2/22: added new feature set of time periods per profile
            availabilities: this.state.content[2].availabilities || []

        };

        // CS 29/4/22: if health edition the admin skills come from step-3 instead of step-4
        if ( isHealthcareEdition)
        {
            createProfile.otherAdminSkill = this.state.content[3].otherText.admin;
            createProfile.adminSkills = Object.keys(this.state.content[3].selected.admin || {});
        }

        if (this.state.type === "person") 
        {
            let register = {
                firstname: this.state.content[5].firstname,
                lastname: this.state.content[5].lastname,
                gender: this.state.content[5].gender.id,
                nationalityId: this.state.content[5].nationality.id,
                hasWorkPermission: true,
                accountRegisterCommand: accountRegister,
                createProfileCommand: createProfile
            };
            ApiController.registerPerson(register, callbackWrapper(this.onResponse.bind(this)));
        } 
        else 
        {
            let register = {
                name: this.state.content[5].companyName,
                address: this.state.content[5].address,
                accountRegisterCommand: accountRegister,
                createProfileCommand: createProfile
            }

            ApiController.registerPharmacy(register, callbackWrapper(this.onResponse.bind(this)));
        }
    }

    onResponse(response) {

        if (response.data.error) 
        {
            if (response.data.error.message === "error.validationFailed") {
                //console.log(response.data);
                NotificationController.pushError(_(response.data.error.message), _("error.registerFailed"));
                return;
            }

            NotificationController.pushError(_(response.data.error.message), _("error.registerFailed"));
            return;
        }

        NotificationController.pushSuccess(_("success.acceptMail"));
        this.props.history.push("/");
    }

    onNext(data) {

        let newState = Object.assign({}, this.state);
        newState.content[this.state.step] = data;

        //console.log(newState);

        if (this.state.step == 5) {
            LoadingController.show();
            this.onCommit();
            return;
        }

        this.setState((prevState, props) => ({
            step: Math.min(5, prevState.step + 1),
            content: newState.content
        }));
    }

    onBack() {
        
        if (this.state.step > 1) {
            this.setState((prevState, props) => ({
                step: prevState.step - 1
            }));
        }
    }

    retrieveStep() {
        switch (this.state.step) {
            case 1:
                return (<Step1Component next={this.onNext.bind(this)} type={this.state.type} state={this.state.content} />);
            case 2:
                return (<Step2Component next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.type} state={this.state.content} />);
            case 3:
                return (<Step3Component next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.type} state={this.state.content} />);
            case 4:
                return (<Step4Component next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.type} state={this.state.content} />);
            case 5:
                return (<Step5Component next={this.onNext.bind(this)} back={this.onBack.bind(this)} type={this.state.type} state={this.state.content} />);
        }
    }

    selectType() {
        return (<SelectTypeComponent update={this.setState.bind(this)} />);
    }

    render() {
        return !this.state.type ? this.selectType() : this.retrieveStep();
    }

}