import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { FormGroup, Button, Form } from 'reactstrap';
import { InputForm } from '../inputFormComponent';
import * as Yup from "yup";
import { _ } from '../../../controllers/languageController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import LoadingItemComponent from '../loadingItemComponent';
import NotificationController from '../../../controllers/notificationController';

class ChangePasswordComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            passwordVisible: false
        };
    }

    onSubmit(fields, { resetForm }) {
        this.setState({ loading: true })
        // CS 28/12/21: removed the need to provide current pwd in order to change to new pwd
        ApiController.updatePassword({
            //actualPassword: fields.currentPassword,
            newPassword: fields.password
        }, callbackWrapper((response) => {
            this.setState({ loading: false });
            if (response.data.error) {
                NotificationController.pushError(_(response.data.error.message), _("error.passwordChangeFailed"));
                return;
            }

            NotificationController.pushSuccess(_("success.passwordChanged"));
        }));
        resetForm();
    }

    // event being called from the 'show password" function button - expires in 10 seconds automatically
    togglePasswordVisibility(e)
    {
        var newVisibility = !this.state.passwordVisible;

        if ( newVisibility){
            setTimeout(function(){
                this.setState({passwordVisible:false});
           }.bind(this),10000);
        }
        this.setState({ passwordVisible: newVisibility });
    }

    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        const validationSchema = Yup.object().shape({
            // currentPassword: Yup.string()
            //     .min(6, _("invalid.password"))
            //     .required(_("no.password")),
            password: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password")),
            passwordConfirm: Yup.string()
                .min(6, _("invalid.password"))
                .required(_("no.password"))
                .oneOf([Yup.ref("password"), null], _("mismatch.password")),
        });

        return (
            <Formik initialValues={{ currentPassword: "", password: "", passwordConfirm: "" }} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    <Form noValidate onSubmit={(e) => {
                        e.preventDefault();
                        props.submitForm(e);
                    }}>
                        {/* <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.passwordOld")} name="currentPassword" type="password" component={InputForm} />
                        </FormGroup> */}

                        <FormGroup className="m-0 mb-2 row">
                            <label className="custom-toggle my-auto ml-auto">
                                {/* <input type="checkbox" checked={this.state.passwordVisible} onChange={() => this.setState({ passwordVisible: !this.state.passwordVisible })} /> */}
                                <input type="checkbox" checked={this.state.passwordVisible} onChange={this.togglePasswordVisibility.bind(this)} />
                                <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                            <h5 className="col-auto m-0">{_("placeholder.displayPassword")}</h5>
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.newPassword")} name="password" type={this.state.passwordVisible ? "text" : "password"} component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.passwordConfirm")} name="passwordConfirm" type={this.state.passwordVisible ? "text" : "password"} component={InputForm} />
                        </FormGroup>

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("changePassword")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>
                )} />
        );
    }

}

export default withRouter(ChangePasswordComponent);