import React, { Component } from 'react';
import AuthenticationController from '../../../controllers/authenticationController';
import ApiController, { callbackWrapper } from '../../../controllers/apiController';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Button } from 'reactstrap';
import { _ } from '../../../controllers/languageController';
import { InputForm } from '../inputFormComponent';
import LoadingItemComponent from '../loadingItemComponent';
import SelectInputComponent, { postalAreaFilter, optionFilter } from '../selectInputComponent';
import NotificationController from '../../../controllers/notificationController';

class EditAccountComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentWillMount() {
        this.load();
    }

    load() {
        if (AuthenticationController.isPerson()) {
            ApiController.retrieveAccountPerson(callbackWrapper(this.loadAccountCallback).bind(this));
        } else {
            ApiController.retrieveAccountPharmacy(callbackWrapper(this.loadAccountCallback).bind(this));
        }
    }

    loadAccountCallback = (response) => {
        this.setState({ content: response.data.result });
        ApiController.retrievePostalArea(response.data.result.account.postalAreaID, callbackWrapper(this.loadPostalAreaCallback).bind(this));
    }

    loadPostalAreaCallback = (response) => {
        this.setState({
            postalArea: {
                id: response.data.result.id,
                item: `${response.data.result.code} ${response.data.result.name}`
            }, loading: false
        });
    }

    retrieveGenders() {
        return [{ id: 0, item: _("gender.other") }, { id: 1, item: _("gender.female") }, { id: 2, item: _("gender.male") }];
    }

    retrieveGender(id) {
        return this.retrieveGenders().filter(x => x.id == id)[0];
    }

    selectGender(gender) {
        let content = Object.assign({}, this.state.content);
        content.gender = gender.id;

        this.setState({ content: content });
    }

    selectArea(area) {
        this.setState({
            postalArea: area, postalAreaUpdate: true
        });
    }

    onSubmit(fields) {

        this.setState({ loading: true, fields: fields });
        ApiController.updateAccount({
            phone: fields.phone,
            postalAreaID: this.state.postalArea.id
        }, callbackWrapper(this.updateAccountCallback.bind(this)).bind(this));
    }

    updateAccountCallback(response) {
        if (response.data.error) {
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            if (AuthenticationController.isPerson()) {
                ApiController.updatePersonAccount({
                    firstname: this.state.fields.firstname,
                    lastname: this.state.fields.lastname,
                    gender: this.state.content.gender
                }, callbackWrapper(this.updateCallback.bind(this)).bind(this));
            } else {
                ApiController.updatePharmacyAccount({
                    name: this.state.fields.companyName,
                    address: this.state.fields.address
                }, callbackWrapper(this.updateCallback.bind(this)).bind(this));
            }
        }
    }

    updateCallback(response) {

        if (response.data.error) {
            this.setState({ loading: false });
            NotificationController.pushError(_(response.data.error.message), _("error.update"));
        } else {
            NotificationController.pushSuccess(_("success.update"));
            this.load();
        }
    }

    renderForPerson() {

        const validationSchema = Yup.object().shape({
            firstname: Yup.string()
                .min(2, _("invalid.name"))
                .required(_("no.name")),
            lastname: Yup.string()
                .min(2, _("invalid.name"))
                .required(_("no.name"))
        });

        const initialValues = {
            firstname: this.state.content.firstname,
            lastname: this.state.content.lastname,
            email: this.state.content.account.email,
            phone: this.state.content.account.phone
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    <Form noValidate className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.firstname")} name="firstname" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.lastname")} name="lastname" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectGender.bind(this)}
                                onFilter={optionFilter(this.retrieveGenders())}
                                default={this.retrieveGender(this.state.content.gender)}
                                placeholder={_("placeholder.gender")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectArea.bind(this)}
                                onFilter={postalAreaFilter}
                                default={this.state.postalArea}
                                placeholder={_("placeholder.postalArea")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.email")} name="email" type="email" readOnly="readOnly" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.phone")} name="phone" component={InputForm} />
                        </FormGroup>

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>

                )}
            />
        );        
    }

    renderForPharmacy() {
        const validationSchema = Yup.object().shape({
            companyName: Yup.string()
                .min(3, _("invalid.companyName"))
                .required(_("no.companyName")),
            address: Yup.string()
                .min(3, _("invalid.address"))
                .required(_("no.address"))
        });

        const initialValues = {
            companyName: this.state.content.name,
            address: this.state.content.address,
            email: this.state.content.account.email,
            phone: this.state.content.account.phone
        };

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit.bind(this)}
                render={(props) => (
                    <Form noValidate className="col-12 p-0" onSubmit={(e) => { e.preventDefault(); props.submitForm(e); }}>
                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.companyName")} name="companyName" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.address")} name="address" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <SelectInputComponent
                                required submitted
                                onSelect={this.selectArea.bind(this)}
                                onFilter={postalAreaFilter}
                                default={this.state.postalArea}
                                placeholder={_("placeholder.postalArea")} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.email")} name="email" type="email" readOnly="readOnly" component={InputForm} />
                        </FormGroup>

                        <FormGroup className="mb-2">
                            <Field placeholder={_("placeholder.phoneNumber")} name="phone" component={InputForm} />
                        </FormGroup>

                        <div className="row">
                            <div className="offset-md-6 col-md-6 text-right">
                                <Button type="submit" className="col-12" color="success">{_("save")} <i className="fas fa-angle-right"></i></Button>
                            </div>
                        </div>
                    </Form>

                )}
            />
        );    
    }


    render() {
        if (this.state.loading) {
            return (<LoadingItemComponent />);
        }

        //
        // CS 15/7/20: created new special render function to seperate the already big and polluted codebases
        // Based on the account type to proper rendering method will be executed
        //
        if (AuthenticationController.isPerson()) return this.renderForPerson();

        return this.renderForPharmacy();
    }

}

export default withRouter(EditAccountComponent);