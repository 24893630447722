import React, { Component } from 'react';
/* import { FormGroup, Button } from 'reactstrap'; */
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
/* import SelectInputComponent, { optionFilter } from '../selectInputComponent'; */
import CenteredComponent from '../centeredComponent';
import { _ } from '../../../controllers/languageController';
import ConfigController from '../../../controllers/configController';
import ApiController from '../../../controllers/apiController';

class AcceptTermsComponent extends Component {

    submit() {
        ApiController.mandatoryChange(this.props.id, {}, this.props.onSubmit());
    }

    render() {
        return (
            <CenteredComponent size={12} className="animated fadeIn">
                <h1 className="mb-2">{_("text.acceptTerms.header")}</h1>
                <h3 className="mb-2 offset-lg-2 col-lg-8">{_("text.acceptTerms.subtitle")}</h3>
                <div className="offset-lg-3 col-lg-6 mb-0">

                    {ConfigController.isPharmacyEdition() && 
                        <a href="https://www.apopersonal.com/agb" target="_blank">{_("text.showTerms")}</a>
                    }
                    {ConfigController.isHealthcareEdition() && 
                        <a href="https://www.pflege-finden.com/agb" target="_blank">{_("text.showTerms")}</a>
                    }

                    <div className="row mt-3">
                        <div className="offset-md-6 col-md-6 text-right">
                            <Button type="submit" className="col-12" color="success" onClick={this.submit.bind(this)}>{_("accept")} <i className="fas fa-angle-right"></i></Button>
                        </div>
                    </div>
                </div>
            </CenteredComponent>
        );
    }

}

export default withRouter(AcceptTermsComponent);