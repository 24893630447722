import jwt from 'jsonwebtoken';
import LanguageController from './languageController';

const AuthenticationController = {

    retrieveToken() {
        if (this.currentToken) return this.currentToken;

        this.currentToken = localStorage.getItem("token");
        return this.currentToken;
    },

    retrieveContent() {
        return jwt.decode(this.retrieveToken());
    },

    setToken(token) {
        this.currentToken = token;
        localStorage.setItem("token", token);

        // CS 4/2/23: added countryCode to JWT token for constant access to origin
        // strip out now and set language immediately to new country lang package
        var info = jwt.decode(token);
        if ( this.isAuthenticated() && info.countryCode != null) 
            LanguageController.changeLanguage(info.countryCode);
    },

    isAuthenticated() {
        // CS 14/5/20: removed the IsAdmin check to allow administrator to logon as well
        //return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && !this.isAdmin();
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken();
    },

    isMandatoryChange() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.pendingMandatoryChange === "True";
        }
        return false;
    },

    isPerson() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.type === "True";
        }
        return false;
    },

    isEmployer() {
        if (this.isAuthenticated()) {
            let content = this.retrieveContent();
            return content.type === "False";
        }
        return false;
    },

    isAdmin() {
        return this.retrieveToken() !== "undefined" && !!this.retrieveToken() && this.retrieveContent().admin === "True";
    },

    logout() {
        this.setToken(undefined);
    }

}

export default AuthenticationController;